import crudFetcher from "@/libs/crudFetcher";
import dataProvider from "@/libs/dataProvider";
import tokenStorage from "@/utils/token";

export const dataProviderNextApi = dataProvider.getInstance(`${global.__env?.APP_NEXT_API}`);
export const crudFetcherNextApi = new crudFetcher(
  dataProviderNextApi,
  tokenStorage
);

export const dataProviderCommon = dataProvider.getInstance(`${global.__env?.APP_API}`);
export const crudFetcherCommon = new crudFetcher(
  dataProviderCommon,
  tokenStorage
);

export const dataProviderSeller = dataProvider.getInstance(`${global.__env?.APP_SELLER_API}`);
export const crudFetcherSeller = new crudFetcher(
  dataProviderSeller,
  tokenStorage
);
