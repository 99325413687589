const API_ACCOUNTS = `/accounts/`;
const API_REGISTERING = `/accounts/registering/`;
const API_TOKEN = `/accounts/`;
const API_SOCIAL_AUTH = `/rest-auth/`;

const apiAuth = {
  signIn: () => `${API_ACCOUNTS}login/`,
  logout: () => `${API_ACCOUNTS}blacklist/`,
  authResendConfirmation: () => `${API_ACCOUNTS}resend-confirmation/`,
  authResendLoginTFA: () => `${API_ACCOUNTS}resend-login-tfa/`,

  tokenRefresh: () => `${API_TOKEN}refresh/`,
  tokenVerify: () => `${API_TOKEN}verify/`,

  googleAuth: () => `${API_SOCIAL_AUTH}google/`,
  googleConnect: () => `${API_SOCIAL_AUTH}google/connect`,
  facebookAuth: () => `${API_SOCIAL_AUTH}facebook/`,
  facebookConnect: () => `${API_SOCIAL_AUTH}facebook/connect`,
  appleAuth: () => `${API_SOCIAL_AUTH}apple/`,
  appleConnect: () => `${API_SOCIAL_AUTH}apple/connect`,
  disconnectSocialAccount: (connectId: number) =>
    `${API_ACCOUNTS}social/${connectId}/disconnect_account/`,

  profile: () => `${API_ACCOUNTS}profile/`,
  profilePhoto: () => `${API_ACCOUNTS}profile/`,

  preferences: () => `${API_ACCOUNTS}preferences/`,

  changePassword: () => `${API_ACCOUNTS}change-password/`,

  authSignUp: () => `${API_REGISTERING}register/`,
  authSellerSignUp: () => `${API_REGISTERING}register-seller/`,
  authResetPassword: () => `${API_REGISTERING}reset-password/`,
  authForgotPassword: () => `${API_REGISTERING}send-reset-password-link/`,
  authVerifyRegistration: () => `${API_REGISTERING}verify-registration/`,
  authResendVerification: () => `${API_REGISTERING}resend-verification/`,

  authLogout: () => `${API_ACCOUNTS}logout/`,

  // TODO not tested
  authRestoreAccount: () => `${API_ACCOUNTS}restore_account/`,
  authVerifyAccountRestoration: () =>
    `${API_ACCOUNTS}verify_account_restoration/`,
  getAnonymousId: () => `${API_ACCOUNTS}get_anon_id/`,
  authResendEmail: () => `${API_ACCOUNTS}resend-email/`,

  authConfirmEmail: () => `${API_ACCOUNTS}confirm-email/`,
  authRegisterEmail: () => `${API_ACCOUNTS}register-email/`,
  authVerifyEmail: () => `${API_ACCOUNTS}verify-email/`,
  // TODO need to remove user id
  authDeleteProfile: (userId: any) => `${API_ACCOUNTS}delete/${userId}/`,
  authUndeleteProfile: () => `${API_ACCOUNTS}profile/restore/`,

  sessionChooseCurrencies: () => `/currencies/choose_currency/`,
  sessionChooseCountry: () => `/country/choose_country/`,
  sessionChooseLanguage: () => `/languages/choose_language/`,
};

export default apiAuth;
