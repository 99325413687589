"use client";
import { SENTRY_LEVEL } from "./sentry_constants";

export enum ClientExceptionTag {
  CUSTOMER = "customer",
  SELLER = "seller",
}

interface ServerCaptureExceptionOptions {
  tags?: { [name: string]: string | number | boolean | undefined | null };
  error?: any;
  data?: any;
  level: SENTRY_LEVEL;
}

const clientCaptureException = async (message: string, options: ServerCaptureExceptionOptions) => {
  const Sentry = await import("@sentry/browser");
  console.log("Sentry", Sentry);

  Sentry.captureException(new Error(message), (scope) => {
    scope.setTag("side", "server");
    if (options.tags) {
      Object.keys(options.tags).forEach((tag) => {
        // @ts-ignore
        scope.setTag(tag, options.tags[tag]);
      });
    }
    if (options.error) {
      scope.setContext("error", options.error);
    }
    if (options.data) {
      options.data.password = "--Filtered--";
      options.data.new_password = "--Filtered--";
      options.data.old_password = "--Filtered--";
      options.data.password_confirm = "--Filtered--";
      scope.setContext("data", options.data);
    }
    return scope;
  });
};

export default clientCaptureException;
