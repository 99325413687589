import {
  IApiCustomerNotificationChannels,
  IApiCustomerNotificationSettingsTypes,
  IApiCustomerNotificationTypes,
} from "./notifications";

export interface IApiPreferences {
  stores: number[];
  currency: string | null;
  language: string | null;
  country: string | null;
  /**
  * @deprecated notification_prefs should not be used
  */
  notification_prefs: IApiNotificationPref[] | null;
}

  /**
  * @deprecated IApiNotificationPref should not be used
  */
export interface IApiNotificationPref {
  notification_type: IApiCustomerNotificationTypes;
  channels: Array<IApiCustomerNotificationChannels>;
}

export enum VERIFICATION_TYPES_2FA {
  sms = 'sms',
  email = 'email',
}

export interface IApiProfile {
  id: number;
  email: string;
  is_email_verified: boolean;
  first_name: string | null;
  last_name: string | null;
  birth_date: string | null;
  phone: string | null;
  is_phone_verified: boolean;
  is_seller: boolean;
  is_manager: boolean;
  is_deleted: boolean;
  avatar: string | null;
  website: string | null;
  is_business_registered: boolean;
  is_two_factor_auth: boolean;
  two_factor_auth_method: string | null;
  notify_configs: {
    id: number;
    notify_category: IApiCustomerNotificationSettingsTypes;
    is_email: boolean;
  }[];
}

export interface IApiSellerProfile extends IApiProfile {
  messenger: string | null;
  messenger_name: string | null;
}

export interface IApiSellerProfile extends IApiProfile {}

export type IApiKYBStep = 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH';

export interface IApiKYBCompanyInfo {
  company_name: string | null;
  legal_name: string | null;
  legal_formation: string | null;
}

export interface IApiKYBContacts {
  state: string | null;
  country: string | null;
  city: string | null;
  street: string | null;
  office: string | null;
  zip_code: string | null;
  email: string | null;
  phone: string | null;
}

export interface IApiKYBBeneficiary {
  id?: number;
  name: string | null;
  title: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
}

export interface IApiKYBBankDetails {
  bank_name: string | null;
  bank_branch_address: string | null;
  account_number: string | null;
  iban: string | null;
}

export interface IApiKYB extends IApiKYBCompanyInfo, IApiKYBContacts, IApiKYBBankDetails {
  id: number;
  step: IApiKYBStep;
  verification_request: boolean;
  beneficiaries: IApiKYBBeneficiary[];
}

export interface IApiKYBFile {
  id: number;
  file: string;
  related_field_name: string;
  beneficiary: number | null;
}

export interface IApiAuthError {
  status: string;
  method: string;
  entity: string;
  code_type: string;
  destination: string;
}
